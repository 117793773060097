<template lang="pug">
.icon-scroll-page
  ui-icon.icon-scroll-page__icon(
    v-if="lastBottom"
    clickable
    :size="24"
    :icon="scrollIcon"
    @click="scrollToPrev"
  )
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { scrollToTop, scrollToSpecified } from "@/utils/scroller/documentScroller";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "IconScrollPageBack",
  components: {
    UiIcon,
  },
  setup() {
    const scrollIcon = ref(UiIconNames.Chevron_CircleUp);

    const lastBottom = ref(NaN);
    let prevPosition = NaN;
    let handScroll = false;
    let elMain: HTMLElement;

    onMounted(() => {
      elMain = document.getElementById("app-main")!;
      elMain.addEventListener("mousewheel", mouseWheelFunction);
    });

    const mouseWheelFunction = () => {
      if (elMain!.scrollTop >= 500) {
        scrollIcon.value = UiIconNames.Chevron_CircleUp;
        lastBottom.value = elMain!.scrollTop;
      } else if (handScroll && prevPosition >= 500) {
        lastBottom.value = NaN;
      }
      handScroll = true;
      prevPosition = elMain!.scrollTop;
    };

    const scrollToPrev = () => {
      const elMainTop = elMain!.scrollTop;
      if (elMainTop > 500) {
        lastBottom.value = elMain!.scrollTop;
        scrollIcon.value = UiIconNames.Chevron_CircleDown;
        scrollToTop();
        handScroll = false;
      } else if (lastBottom.value) {
        scrollIcon.value = UiIconNames.Chevron_CircleUp;
        scrollToSpecified(lastBottom.value, 'smooth');
        handScroll = false;
      } else {
        lastBottom.value = NaN;
      }
    };

    return {
      scrollToPrev,
      scrollIcon,
      lastBottom,
    };
  },
});
</script>

<style scoped lang="scss">
.icon-scroll-page {
  position: fixed;
  bottom: 120px;
  right: 40px;
}
.icon-scroll-page__icon {
  color: #bdbdbd;
  border-radius: 20px;
  padding: 5px;
}
</style>
