<template lang="pug">
.header(:class="!isBlocked && !isPlanExpired && 'full'")
  .header__logo(v-if="!isBlocked && !isPlanExpired")
    accessed-link(:name="Tabs.Search")
      ui-logo.main-logo

  .header__content
    .header-list
      main-header-link.header__username(
        :class="isTmManager && 'admin-name'"
        :label="me.surName + ' ' + me.firstName"
        :icon="UiIconNames.Icon_User"
        :route-name="Tabs.Settings.Account"
        :disabled="isTmManager || isBlocked || isPlanExpired"
      )

      main-header-link.header__tariff(
        v-if="!isTmManager && !isBlocked"
        :raw-tooltip="true"
        :label="company?.dateEndTariff?.replaceAll('-', '.') + ' МСК'"
        :tooltip="isPlanExpired ? expiredTooltip : 'Дата окончания тарифа'"
        :route-name="isPlanExpired ? Tabs.PageExpired : Tabs.Settings.Tariffs"
        :class="(isPlanExpired || getDaysUntil(dateEndTariffParsed) < 5) && '_expiring'"
        :disabled="!roleAdmin"
      )
        template(#icon)
          ui-icon.header__icon(:size="16" :icon="isPlanExpired ? UiIconNames.Icon_Lock : UiIconNames.Icon_Timer" :class="(isPlanExpired || getDaysUntil(dateEndTariffParsed) < 5) && 'red'")

      main-header-link.header__timezone(
        v-if="!isTmManager && !isBlocked"
        tooltip="Вы можете изменить часовой пояс в профиле пользователя"
        :label="getTimezoneString(me.timezone)"
        :icon="UiIconNames.Icon_Clock"
        :route-name="Tabs.Settings.Account"
        :disabled="isBlocked || isPlanExpired"
      )

      .header-tm3-company(v-if="isTmManager && !isBlocked")
        tm-company-selector(:model-value="selectedCompanyInfo" @update:model-value="updateSelectedCompany")

      main-header-link(
        v-if="isTmManager && managerCompanyId && !isBlocked"
        label="Информация о компании"
        :route-name="Tabs.AdminPages.PageTariffChanger"
        :icon="UiIconNames.Icon_Home"
      )

      main-header-link(
        v-if="isTmManager && managerCompanyId && getDaysUntil(dateEndTariffParsed) < 5"
        :tooltip="isClientPlanExpired ? 'Тариф клиента истек' : 'Тариф клиента истекает'"
        :route-name="Tabs.AdminPages.PageTariffChanger"
      )
        template(#icon)
          ui-icon.header__icon.red(:size="16" :icon="UiIconNames.Icon_Timer")

      main-header-link(
        v-if="isTmManager && selectedCompanyInfo?.isBlocked"
        tooltip="Аккаунт компании был заблокирован"
        :route-name="Tabs.AdminPages.PageTariffChanger"
      )
        template(#icon)
          ui-icon.header__icon.red(:size="16" :icon="UiIconNames.Icon_Lock")
    .header-list

      main-header-link(
        v-if="!isTmManager && !isBlocked && !isPlanExpired && (enableSearchTab || enableLotManagement)"
        tooltip="Управление тегами"
        type="button"
        :icon="UiIconNames.Icon_Tag"
        @click="onOpenTagEditor"
      )

      main-header-link(
        v-if="!isTmManager && !isBlocked && !isPlanExpired"
        tooltip="История уведомлений"
        :route-name="Tabs.NotificationHistory"
        :icon="UiIconNames.Icon_Notification"
      )

      contacts-tooltip(v-if="!isTmManager")

      main-header-link(
        v-if="isTmManager && !isBlocked"
        label="Панель администратора"
        :route-name="Tabs.AdminPages.ManagersList"
        :icon="UiIconNames.Icon_Setup"
      )

      main-header-link(
        label="Выход"
        type="button"
        @click="onLogout"
      )
        template(#icon)
          mini-loader(v-if="logoutLoader")
          ui-icon.header__icon(v-else clickable :size="16" :icon="UiIconNames.Icon_Exit")
</template>

<script lang="ts">
import { defineComponent, computed, ref, } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useUserAccess } from "@/use/userRoleAccess/useUserAccess";
import { useSuperAccount } from "@/use/superAccount/useSuperAccount";
import { getTimezoneString, getDaysUntil } from "@/utils/formatter/dateFormatter";
import { parse } from 'date-fns';
import { useTagsEditor } from "~/globals/tags/useTagsEditor";

import useAuthStore from "@/stores/auth/useAuthStore";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";
import useNotificationsStore from "~/stores/systemNotifications/useNotificationsStore";

import UiIconNames from "@/components/ui/icon/UiIconNames";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiLogo from "@/components/ui/logo/UiLogo.vue";
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";
import AccessedLink from "@/components/ui/links/AccessedLink.vue";
import TmCompanySelector from "@/components/selector/superAccount/TmCompanySelector.vue";
import MiniLoader from "~/components/ui/loader/MiniLoader.vue";
import MainHeaderLink from "~/components/header/MainHeaderLink.vue";
import ContactsTooltip from "~/components/header/ContactsTooltip.vue";

import { Tabs } from "@/router/tabs";
import { availableForManager } from "~/utils/authorization/auth";

export default defineComponent({
  name: "MainHeader",
  methods: {
    getDaysUntil,
    getTimezoneString,
  },
  components: {
    ContactsTooltip,
    MainHeaderLink,
    MiniLoader,
    UiIcon,
    UiLogo,
    OldTooltip,
    AccessedLink,
    TmCompanySelector,
  },
  setup() {

    const logoutLoader = ref(false);

    const { me, company, isTmManager, isBlocked, isPlanExpired, roleAdmin, enableSearchTab, enableLotManagement } = useUserAccess();
    const { managerCompanyId } = useSuperAccount();
    const { showSuccess } = useNotificationsStore();

    const authStore = useAuthStore();
    const { logout } = authStore;

    const onLogout = () => logout(logoutLoader)

    const tenmonManagerStore = useTmManagerStore();
    const { isClientPlanExpired, selectedCompanyInfo } = storeToRefs(tenmonManagerStore);
    const { resetClientData } = tenmonManagerStore;

    const [ date, time ] = company.value?.dateEndTariff?.split(' ') || ['', ''];
    const expiredTooltip = `Ваш тариф истек ${ date } в ${ time } МСК. <br /> Оставьте заявку, чтобы продлить тариф.`;

    const dateEndTariffParsed = computed(() => {
      if (isTmManager.value && selectedCompanyInfo.value) return parse(selectedCompanyInfo.value?.dateEndTariff + ' +0300', 'dd-MM-yyyy HH:mm x', new Date())
      if (!isTmManager.value) return parse(company.value?.dateEndTariff + ' +0300', 'dd-MM-yyyy HH:mm x', new Date())
      return ''
    })

    const route = useRoute();
    const router = useRouter();

    function updateSelectedCompany(c: any) {
      if (c) selectedCompanyInfo.value = c
      else resetClientData()

      if (!c && !availableForManager.includes(route.name)) router.push({ name: Tabs.Services })
      if (c) router.push({ name: Tabs.AdminPages.PageTariffChanger })
    }

    const { openTagEditor } = useTagsEditor();

    function onOpenTagEditor() {
      if (route.name !== Tabs.Settings.TagsSettings) openTagEditor();
      else showSuccess('Вы уже находитесь на вкладке управления тегами')
    }

    return {
      me,
      company,
      router,
      roleAdmin,
      dateEndTariffParsed,
      isTmManager,
      isPlanExpired,
      isBlocked,
      isClientPlanExpired,
      enableSearchTab,
      enableLotManagement,
      selectedCompanyInfo,
      expiredTooltip,
      managerCompanyId,
      logoutLoader,
      onOpenTagEditor,
      onLogout,
      updateSelectedCompany,
      UiIconNames,
      Tabs,
    };
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-right: 6px;
  background-color: white;

  &.full {
    display: grid;
    grid-template-columns: 120px 1fr;
  }

  .header__logo {
    display: flex;
    box-sizing: border-box;

    .main-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 92px;
      padding-left: 14px;
      height: auto !important;
    }
  }
}

.header__content {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: var(--max-content-width);
  justify-self: center;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  box-sizing: border-box;
}

.header-list {
  display: flex;
  align-items: center;
  gap: 16px;
}

.header-tm3-company {
  width: 240px;
}

.header__tariff {
  &._expiring {
    color: var(--main-red-color);
  }
}

.header__icon {
  color: #409eff;
  align-self: center;

  &.red {
    color: var(--main-red-color);
  }
}

@media (max-width: 1080px) {
  .header-list {
    gap: 8px;
  }
  .admin-name {
    display: none;
  }
}

@media (max-width: 800px) {
  .header__username {
    display: none;
  }
}

@media (max-width: 600px) {
  .header__tariff {
    display: none;
  }
}
</style>
