<template lang="pug">
teleport(to="body")
  .modal-window(v-if="show")
    ui-loader.loader-content(:label="`Скачивание файла \"${ fileName }\"`")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiLoader from "@/components/ui/loader/UiLoader.vue";

export default defineComponent({
  name: "PageLoader",
  components: {
    UiLoader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fileName: {
      type: String,
      default: '',
    },
  },
});
</script>

<style scoped lang="scss">
$modal-dialog-background-color: rgba(255, 255, 255, .9);

.modal-window {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: $modal-dialog-background-color;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
}

.loader-content {
  z-index: 12;
  max-width: 400px;
  display: flex;
  text-align: center;
}
</style>
