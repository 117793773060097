<template lang="pug">
ui-dialog(
  closable
  modal
  width="640px"
  :z-index="3"
  :visible-prop="isOpen"
  header="Настройка тегов"
  @content:hide="hide"
)
  template(#content)
    tags-management(ref="content" @tag:delete="setReload")
    span.error-message(v-if="showMessage") У Вас есть несохраненные изменения. Сохраните их, или повторите попытку закрыть окно
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useTagsEditor } from "~/globals/tags/useTagsEditor";

import UiButton from "~/components/ui/button/UiButton.vue";
import UiDialog from "~/components/ui/dialog/UiDialog.vue";
import TagsManagement from "~/views/settings/tags/TagsManagement.vue";

export default defineComponent({
  name: "TagsEditorDialog",
  components: {
    UiDialog,
    UiButton,
    TagsManagement,
  },
  setup() {

    const content = ref();
    const showMessage = ref(false);

    const reload = ref(false);

    const {
      isOpen,
      setNeedReloadData,
      closeTagEditor,
    } = useTagsEditor();

    function hide() {
      if (showMessage.value) close()
      else if (content.value && content.value.editableTag) showMessage.value = true
      else close()
    }

    function setReload() {
      reload.value = true
    }

    function close() {
      showMessage.value = false
      if (reload.value) setNeedReloadData()
      closeTagEditor()
    }

    return {
      content,
      showMessage,
      setReload,
      hide,
      isOpen,
      closeTagEditor,
    };
  },
});
</script>

<style scoped lang="scss">
.error-message {
  font-size: 13px;
  line-height: 18px;
  color: var(--main-red-color);
}
</style>
