<template lang="pug">
old-tooltip(tooltip-position="bottom-end")
  a.header__item._clickable(:href="FreePhoneNumber.link")
    ui-icon.header__icon(:size="16" :icon="UiIconNames.Icon_Support")
    span {{ FreePhoneNumber.value }}
  template(#content)
    .contact-us
      span Телефон бесплатный для звонков по РФ.
      span Свяжитесь с нами, и наши специалисты проконсультируют Вас по работе с системой и ответят на все Ваши вопросы.
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { FreePhoneNumber } from "@/const/contacts";

import UiIcon from "~/components/ui/icon/UiIcon.vue";
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "ContactsTooltip" ,
  components: {
    UiIcon,
    OldTooltip,
  },
  setup() {
    return {
      FreePhoneNumber,
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/elements/header";

.header__icon {
  color: #FF9C41 !important;
}

.contact-us {
  display: flex;
  flex-flow: column;
  gap: 8px;

  font-size: 12px;
  line-height: 16px;
  padding: 4px 8px;
  max-width: 320px;
}
</style>
