<template lang="pug">
.header-link(v-if="type === 'link'")
  old-tooltip(:content="tooltip" :raw-content="rawTooltip")
    accessed-link.header__item(
      :name="routeName"
      :disabled="disabled"
      :class="!disabled && '_clickable'"
    )
      slot(name="icon")
        ui-icon.header__icon(v-if="icon" :size="16" :icon="icon")
      span(v-if="label") {{ label }}
      old-tooltip(v-if="warning" :content="warning" size="small" :icon="warningIcon")

.header-button(v-else-if="type === 'button'")
  old-tooltip(:content="tooltip" :raw-content="rawTooltip" :tooltip-position="tooltipPosition")
    .header__item._clickable
      slot(name="icon")
        ui-icon.header__icon(v-if="icon" :size="16" :icon="icon")
      span(v-if="label") {{ label }}
      old-tooltip(v-if="warning" :content="warning")
    template(#content v-if="$slots.content")
      slot(name="content")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";

import UiIcon from "~/components/ui/icon/UiIcon.vue";
import AccessedLink from "~/components/ui/links/AccessedLink.vue";
import OldTooltip from "~/components/ui/tooltip/OldTooltip.vue";

export default defineComponent({
  name: "MainHeaderLink",
  components: {
    UiIcon,
    OldTooltip,
    AccessedLink,
  },
  props: {
    type: {
      type: String as PropType<'link'|'button'>,
      default: 'link',
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    routeName: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    rawTooltip: {
      type: Boolean,
      default: false,
    },
    tooltipPosition: {
      type: String as PropType<"bottom" | "bottom-end">,
      default: "bottom",
    },
  },
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/elements/header";
</style>
