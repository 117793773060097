<template lang="pug">
.update-app
  Transition(name="alert")
    AlertMessage(v-if="needUpdate" type="success")
      template(#label) Доступно обновление
      template(#content)
        button.action(@click="onClickUpdate" type="button") Перезагрузите
        |
        | страницу, чтобы обновить.
</template>

<script setup lang="ts">
import AlertMessage from "@/components/ui/alert/AlertMessage.vue";

const nuxtApp = useNuxtApp()
const needUpdate = ref(false)

nuxtApp.hook("app:manifest:update", () => {
  needUpdate.value = true
})

function onClickUpdate() {
  reloadNuxtApp();
}
</script>

<style scoped lang="scss">
.update-app {
  border-radius: 4px;
  position: fixed;
  z-index: 100;
  top: 16px;
  right: 16px;
  width: 400px;

  .action {
    font-weight: bold;
    cursor: pointer;
    color: inherit;
    font-size: inherit;
    line-height: inherit;

    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
  }

  .alert-enter-active,
  .alert-leave-active {
    transition: all .3s ease;
  }

  .alert-enter-from,
  .alert-leave-to {
    opacity: 0;
    transform: translateX(20px);
  }
}
</style>
