<template lang="pug">
ui-tooltip(placement="right-end")
  .nav-item
    ui-icon.icon(:size="14" :icon="icon")
    .tab-label(v-if="label") {{ label }}
  template(v-if="$slots.content" v-slot:content)
    slot(name="content")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiTooltip from "~/components/ui/tooltip/UiTooltip.vue";

export default defineComponent({
  name: "NavMenuItem",
  components: {
    UiIcon,
    UiTooltip,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/elements/aside";
</style>
