<template lang="pug">
ui-tooltip(placement="right-start" close-on-click)
  router-link.nav-item(:to="tab.path" :class="getItemBackground(tab.key)")
    ui-icon.icon(:size="14" :icon="tab.icon" :class="tab.highlight && '_highlight'")
    .tab-label
      span {{ tab.label }}
      ui-icon.chevron(
        v-if="showChevron"
        :size="12"
        :icon="UiIconNames.Chevron_Right"
      )
  template(#content v-if="showChevron")
    .child-tabs
      router-link.child-tab(:to="tab.path" :class="getItemBackground(tab.key, false)")
        ui-icon.icon(:size="14" :icon="tab.icon")
        div Все {{ tab.label }}
      template(v-for="child in tab.child" :key="child.key")
        router-link.child-tab(
          v-if="child.show"
          :to="child.path"
          :class="getItemBackground(child.key, false)"
        )
          ui-icon.child.icon(:size="14" :icon="child.icon")
          div {{ child.label }}
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import { Tabs } from "@/router/tabs";

import type { PropType } from "vue";
import type { MenuItemI } from "@/stores/settings/SettingsInterfaces";

import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiTooltip from "~/components/ui/tooltip/UiTooltip.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "NavMenuLink",
  components: {
    UiIcon,
    UiTooltip,
  },
  props: {
    tab: {
      type: Object as PropType<MenuItemI>,
      default: () => ({}),
    },
  },
  setup(props) {

    const route = useRoute();
    const routeName = computed(() => (route.name as string) || "");
    const routeTab = computed(() => (route.path.split("/")[1] as string) || "");

    const showChevron = computed(() => props.tab?.child && props.tab.child.some(c => c?.show))

    function getItemBackground(tabName: string, withIndeterminate = true): string | undefined {
      if (!routeName.value) return;
      if (
        routeTab.value === tabName
        && routeName.value !== tabName
        && (tabName === Tabs.Applications.Main || tabName === Tabs.Contracts.Main)
      ) {
        return withIndeterminate ? "_indeterminate" : "";
      } else if (routeName.value === tabName || routeTab.value === tabName) {
        return "_active";
      }
    }

    return {
      Tabs,
      showChevron,
      UiIconNames,
      getItemBackground,
    };
  },
})
</script>

<style scoped lang="scss">
@import "@/assets/styles/elements/aside";
</style>
